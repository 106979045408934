.hc_container {
  perspective: 1000px;
}

.hc_inner {
  transform-style: preserve-3d;
  transform: rotateY(0deg) rotateZ(0deg) translateY(2%);
  transform-origin: 80% 50%;
}

.hc_head-back {
  transform: translateZ(0.1px);
}

.hc_head-front {
  transform: translateZ(0px) rotateX(180deg);
}

.hc_body-back {
  transform: rotateY(180deg) translateZ(1px);
}

.hc_body {
  transform-style: preserve-3d;
}

.hc_inner--front-flip {
  transform: rotateY(25deg) rotateX(0deg) rotateZ(-10deg) translateZ(10px) translateX(-15%)
    scale(0.8);
}

.hc_inner--front-flip .hc_head {
  transform-style: preserve-3d;
  transform: rotateX(50deg);
}

.hc_inner--front-flip-full {
  transform: rotateY(25deg) rotateX(1deg) rotateZ(-10deg) translateZ(10px) scale(0.7)
    translateY(15%) translateX(-20%);
}

.hc_inner--front-flip-full .hc_head {
  transform-style: preserve-3d;
  transform: rotateX(180deg);
}

.hc_inner--back {
  transform: rotateY(180deg) rotateX(1deg) rotateZ(0deg) translateZ(0px) scale(1) translateX(60%);
}

.hc_inner--back-flip-full {
  transform: rotateY(180deg) rotateX(1deg) rotateZ(-10deg) translateZ(10px) scale(0.7)
    translateY(20%) translateX(74%);
}

.hc_inner--back-flip-full .hc_head {
  transform-style: preserve-3d;
  transform: rotateX(180deg);
}

.hc_fresh-sample {
  transform-style: preserve-3d;
  transform: translateX(0%) translateZ(30px) rotateY(0deg);
}
